import './bootstrap';
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import "boxicons";

const docTitle = document.title;

window.onscroll = function adClassOnScroll() {
    var body = document.body;
    if (
        document.body.scrollTop > 70 ||
        document.documentElement.scrollTop > 70
    ) {
        body.classList.add("scrolled");
    } else {
        body.classList.remove("scrolled");
    }
};

window.onblur = () => {
    document.title = 'Come Back To LWA';
}

window.onfocus = () => {
    document.title = docTitle;
}


window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.start();
